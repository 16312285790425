import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, LineChart, Line } from 'recharts'
import { WtxColors } from '@wavetronix/common-components'
import { ColorOptions } from '../../utils/colorOptions.js'
import { useMemo, useState } from 'react'

const TYPE_COLORS = {
  WAN: ColorOptions['WAN Orange'],
  LAN: WtxColors.IQ_BLUE,
  DUAL: WtxColors.ULTRA_VIOLET,
  DC: {
    WAN: {
      LOW: '#6e4c0e',
      HIGH: '#f7c465'
    },
    LAN: {
      LOW: '#244980',
      HIGH: '#7dacf0'
    },
    DUAL: {
      LOW: '#331f4a',
      HIGH: '#b482ed'
    }
  }
}

const HAPPY_LINE_STYLE = {
  HAPPYtrue: '5 5',
  HAPPYfalse: ''
}

const DC_DOT_STYLE = {
  REGULAR: true,
  WAN: {
    LOW: { stroke: TYPE_COLORS.WAN, strokeWidth: 15 },
    // HIGH: <SquareDot color={TYPE_COLORS.WAN} />
    HIGH: { stroke: TYPE_COLORS.WAN, strokeWidth: 10 }
  },
  LAN: {
    LOW: { stroke: TYPE_COLORS.LAN, strokeWidth: 15 },
    // HIGH: <SquareDot color={TYPE_COLORS.LAN} />
    HIGH: { stroke: TYPE_COLORS.LAN, strokeWidth: 10 }
  },
  DUAL: {
    LOW: { stroke: TYPE_COLORS.DUAL, strokeWidth: 15 },
    // HIGH: <SquareDot color={TYPE_COLORS.DUAL} />
    HIGH: { stroke: TYPE_COLORS.DUAL, strokeWidth: 10 }
  }
}

const COLOR_LIST = Object.values(ColorOptions)

export const CABINET_LINEOPTIONS = {
  LAN_HAPPYtrue: {
    value: 'LAN_HAPPYtrue',
    color: TYPE_COLORS.LAN,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.REGULAR,
    name: 'LAN Customer',
    prettyName: 'LAN Customer',
    order: 2
  },
  LAN_HAPPYfalse: {
    value: 'LAN_HAPPYfalse',
    color: TYPE_COLORS.LAN,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.REGULAR,
    name: 'LAN',
    prettyName: 'LAN',
    order: 1
  },
  LAN_HAPPYtrue_DCtrue_ISLOWtrue: {
    value: 'LAN_HAPPYtrue_DCtrue_ISLOWtrue',
    color: TYPE_COLORS.DC.LAN.LOW,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.LAN.LOW,
    name: 'LAN Customer DC 12V',
    prettyName: 'LAN Customer',
    order: 5
  },
  LAN_HAPPYtrue_DCtrue_ISLOWfalse: {
    value: 'LAN_HAPPYtrue_DCtrue_ISLOWfalse',
    color: TYPE_COLORS.DC.LAN.HIGH,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.LAN.HIGH,
    name: 'LAN Customer DC 24V',
    prettyName: 'LAN Customer',
    order: 6
  },
  LAN_HAPPYfalse_DCtrue_ISLOWtrue: {
    value: 'LAN_HAPPYfalse_DCtrue_ISLOWtrue',
    color: TYPE_COLORS.DC.LAN.LOW,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.LAN.LOW,
    name: 'LAN DC 12V',
    prettyName: 'LAN',
    order: 3
  },
  LAN_HAPPYfalse_DCtrue_ISLOWfalse: {
    value: 'LAN_HAPPYfalse_DCtrue_ISLOWfalse',
    color: TYPE_COLORS.DC.LAN.HIGH,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.LAN.HIGH,
    name: 'LAN DC 24V',
    prettyName: 'LAN',
    order: 4
  },
  WAN_HAPPYtrue: {
    value: 'WAN_HAPPYtrue',
    color: TYPE_COLORS.WAN,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.REGULAR,
    name: 'WAN Customer',
    prettyName: 'WAN Customer',
    order: 8
  },
  WAN_HAPPYfalse: {
    value: 'WAN_HAPPYfalse',
    color: TYPE_COLORS.WAN,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.REGULAR,
    name: 'WAN',
    prettyName: 'WAN',
    order: 7
  },
  WAN_HAPPYtrue_DCtrue_ISLOWtrue: {
    value: 'WAN_HAPPYtrue_DCtrue_ISLOWtrue',
    color: TYPE_COLORS.DC.WAN.LOW,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.WAN.LOW,
    name: 'WAN Customer DC 12V',
    prettyName: 'WAN Customer',
    order: 11
  },
  WAN_HAPPYtrue_DCtrue_ISLOWfalse: {
    value: 'WAN_HAPPYtrue_DCtrue_ISLOWfalse',
    color: TYPE_COLORS.DC.WAN.HIGH,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.WAN.HIGH,
    name: 'WAN Customer DC 24V',
    prettyName: 'WAN Customer',
    order: 12
  },
  WAN_HAPPYfalse_DCtrue_ISLOWtrue: {
    value: 'WAN_HAPPYfalse_DCtrue_ISLOWtrue',
    color: TYPE_COLORS.DC.WAN.LOW,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.WAN.LOW,
    name: 'WAN DC 12V',
    prettyName: 'WAN',
    order: 9
  },
  WAN_HAPPYfalse_DCtrue_ISLOWfalse: {
    value: 'WAN_HAPPYfalse_DCtrue_ISLOWfalse',
    color: TYPE_COLORS.DC.WAN.HIGH,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.WAN.HIGH,
    name: 'WAN DC 24V',
    prettyName: 'WAN',
    order: 10
  },
  DUAL_HAPPYtrue: {
    value: 'DUAL_HAPPYtrue',
    color: TYPE_COLORS.DUAL,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.REGULAR,
    name: 'DUAL Customer',
    prettyName: 'DUAL Customer',
    order: 14
  },
  DUAL_HAPPYfalse: {
    value: 'DUAL_HAPPYfalse',
    color: TYPE_COLORS.DUAL,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.REGULAR,
    name: 'DUAL',
    prettyName: 'DUAL',
    order: 13
  },
  DUAL_HAPPYtrue_DCtrue_ISLOWtrue: {
    value: 'DUAL_HAPPYtrue_DCtrue_ISLOWtrue',
    color: TYPE_COLORS.DC.DUAL.LOW,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.DUAL.LOW,
    name: 'DUAL Customer DC 12V',
    prettyName: 'DUAL Customer',
    order: 17
  },
  DUAL_HAPPYtrue_DCtrue_ISLOWfalse: {
    value: 'DUAL_HAPPYtrue_DCtrue_ISLOWfalse',
    color: TYPE_COLORS.DC.DUAL.HIGH,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYtrue,
    dot: DC_DOT_STYLE.DUAL.HIGH,
    name: 'DUAL Customer DC 24V',
    prettyName: 'DUAL Customer',
    order: 18
  },
  DUAL_HAPPYfalse_DCtrue_ISLOWtrue: {
    value: 'DUAL_HAPPYfalse_DCtrue_ISLOWtrue',
    color: TYPE_COLORS.DC.DUAL.LOW,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.DUAL.LOW,
    name: 'DUAL DC 12V',
    prettyName: 'DUAL',
    order: 15
  },
  DUAL_HAPPYfalse_DCtrue_ISLOWfalse: {
    value: 'DUAL_HAPPYfalse_DCtrue_ISLOWfalse',
    color: TYPE_COLORS.DC.DUAL.HIGH,
    strokeDasharray: HAPPY_LINE_STYLE.HAPPYfalse,
    dot: DC_DOT_STYLE.DUAL.HIGH,
    name: 'DUAL DC 24V',
    prettyName: 'DUAL',
    order: 16
  }
}

export default function FullCabinetLineGraph({ barData, customXAxis = false, showPercentLabels = false }) {
  const [tooltip, setToolTip] = useState()

  const CustomTooltip = ({ active, payload, tooltip, barData }) => {
    const productInfo = useMemo(() => {
      if (payload && barData && payload.length > 0 && payload[0].payload) {
        let products = barData.filter(bar => bar.option === payload[0].payload.option)
        return products ? products[0] : null
      }
    }, [payload, barData])
    if (!active || !tooltip || !productInfo) return null
    const optionsToDisplay = Object.values(CABINET_LINEOPTIONS).filter(
      lineOption => productInfo[`${lineOption.value}`].passedPercent
    )
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '10px',
          opacity: 1,
          border: '2px solid black',
          borderRadius: '15px',
          color: 'black'
        }}
      >
        {productInfo.testDevices ? (
          productInfo.testDevices.map((d, idx) => (
            <div key={idx} className='details' style={{ marginLeft: '10px' }}>
              <span style={{ marginRight: '10px', fontWeight: 'bold', display: 'inline-block', color: COLOR_LIST[d.port] }}>
                {d.product}
              </span>
              <span style={{ fontFamily: 'Source Code Pro' }}>{`Slot:${d.port} ${d.softwareVersion}`}</span>
            </div>
          ))
        ) : (
          <div></div>
        )}
        <hr />
        {optionsToDisplay && optionsToDisplay.length > 0
          ? optionsToDisplay.map((lineOption, index) => (
              <div key={index} className='details' style={{ marginLeft: '10px' }}>
                <span style={{ fontWeight: 'bold', display: 'inline-block', color: lineOption.color }}>
                  {CABINET_LINEOPTIONS[lineOption.value].prettyName}
                </span>
                <span style={{ fontFamily: 'Source Code Pro' }}> - {productInfo[`${lineOption.value}`].passedPercent}%</span>
              </div>
            ))
          : null}
      </div>
    )
  }

  const CustomTick = ({ x, y, stroke, payload, barData }) => {
    if (!payload || !barData) return null

    return (
      <g transform={`translate(${x},${y})`}>
        {barData[payload.value].testDevices.slice(0, 3).map((test, index) => (
          <text
            x={0}
            y={index * 15}
            dy={16}
            dx={30}
            textAnchor='end'
            fill='#666'
            key={index}
            className='productVersion'
            style={{
              fill: COLOR_LIST[test.port]
            }}
          >
            {test.softwareVersion.length > 13 ? `${test.softwareVersion.slice(0, 8)}...` : test.softwareVersion}
          </text>
        ))}
      </g>
    )
  }

  return (
    <ResponsiveContainer width='97%' height={'100%'}>
      <LineChart
        width={450}
        height={200}
        data={barData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        {customXAxis === false ? <XAxis dataKey='option' /> : <XAxis tick={<CustomTick barData={barData} />} height={50} />}
        <YAxis domain={[0, 100]} />
        {customXAxis === false ? <Tooltip /> : <Tooltip content={<CustomTooltip tooltip={tooltip} barData={barData} />} />}

        {Object.values(CABINET_LINEOPTIONS).map((v, index) => (
          <Line
            key={index}
            type='monotone'
            dataKey={`${v.value}.passedPercent`}
            stroke={v.color}
            strokeDasharray={v.strokeDasharray}
            onMouseOver={() => setToolTip('option')}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
