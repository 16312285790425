import LoadingPageMessage from './LoadingPageMessage'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  TextField,
  Card,
  CardContent,
  CardActions
} from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { CenteredDiv, PrimaryButton, SnackbarVariants } from '@wavetronix/common-components'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import DtsReportsApi from '../api/DtsReportsApi'
import { useSnackbar } from 'notistack'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

const DEFAULT_LAYOUT = {
  id: '',
  isCarousel: false,
  carouselPages: [],
  includedCabinets: [],
  largeLayout: [],
  mediumLayout: [],
  smallLayout: [],
  extSmallLayout: []
}

export default function ConfigureLayouts({ allLayouts, allLayoutsLoading, reportsData, layoutsRefetch }) {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [includedCabinets, setIncludeCabinets] = useState(null)
  const [newLayoutName, setNewLayoutName] = useState('')

  useEffect(() => {
    if (selectedLayout && reportsData) {
      setIncludeCabinets(selectedLayout.cabinets)
    }
  }, [selectedLayout, reportsData])

  const cabinetOptions = useMemo(() => {
    if (reportsData && includedCabinets) {
      const includeCIds = includedCabinets.map(c => c.id)
      return reportsData.cabinetOverviews.filter(c => !includeCIds.includes(c.id))
    }
  }, [includedCabinets, reportsData])

  // Reorder logic when drag ends
  const handleOnDragEnd = result => {
    if (!result.destination) return // Dropped outside the list

    const reorderedItems = Array.from(includedCabinets)
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)

    setIncludeCabinets(reorderedItems)
  }

  if (allLayoutsLoading) {
    return <LoadingPageMessage message={'Loading all layout configurations. Please wait...'} />
  }

  if (!reportsData) {
    return <LoadingPageMessage message={'Loading reports data. Please wait...'} />
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={3} xl={2}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px' }}>Layouts</h3>
            <IconButton
              id='configureNewLayoutButton'
              sx={{ marginTop: '15px', marginLeft: '15px' }}
              onClick={() => {
                setSelectedLayout(DEFAULT_LAYOUT)
              }}
            >
              <AddIcon />
            </IconButton>
          </CenteredDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px', marginTop: '27px' }}>Cabinets</h3>
          </CenteredDiv>
        </Grid>
        <Grid item lg={6} xl={8}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px', marginTop: '27px' }}>Change Configuration</h3>
          </CenteredDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <List>
            {allLayouts
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map(layout => (
                <ListItem key={layout.id} disablePadding>
                  <ListItemButton
                    sx={{ borderRadius: '20px', boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.9)', marginBottom: '15px' }}
                  >
                    <ListItemIcon
                      id={`${layout.id}LayoutListItemDeleteButton`}
                      onClick={async () => {
                        if (layout.id !== 'InternalLayout') {
                          await DtsReportsApi.deleteCardLayouts(layout.id).then(async () => await layoutsRefetch())
                          enqueueSnackbar('Successfully delete configuration.', SnackbarVariants.SUCCESS)
                          setSelectedLayout(null)
                        } else {
                          enqueueSnackbar('InternalLayout is the default layout and cannot be deleted.', SnackbarVariants.ERROR)
                        }
                      }}
                    >
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText
                      id={`${layout.id}LayoutListItemSelectButton`}
                      primary={layout.id}
                      onClick={() => {
                        setSelectedLayout(layout)
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item lg={3} xl={2}>
          {selectedLayout ? (
            <List>
              {cabinetOptions
                ? cabinetOptions
                    .sort((a, b) => (a.cabinetName > b.cabinetName ? 1 : -1))
                    .map(cabinet => (
                      <ListItem key={cabinet.id} disablePadding>
                        <ListItemButton
                          sx={{ borderRadius: '20px', boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.9)', marginBottom: '15px' }}
                        >
                          <ListItemIcon
                            id={`${cabinet.id}CabinetItemAddButton`}
                            onClick={() => {
                              setIncludeCabinets(cabinets => [...cabinets, { id: cabinet.id, name: cabinet.cabinetName }])
                            }}
                          >
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText primary={cabinet.cabinetName} />
                        </ListItemButton>
                      </ListItem>
                    ))
                : []}
            </List>
          ) : (
            <CenteredDiv>Selected a layout to edit or create a new one.</CenteredDiv>
          )}
        </Grid>
        <Grid item lg={6} xl={8}>
          {selectedLayout ? (
            <Card>
              <CardContent>
                {selectedLayout.id === '' ? (
                  <TextField
                    id='newLayoutIdTextField'
                    label='Layout Name'
                    size='small'
                    value={newLayoutName}
                    onChange={e => setNewLayoutName(f => e.target.value)}
                  />
                ) : (
                  <h4>Layout Name: {selectedLayout.id}</h4>
                )}
                {selectedLayout && includedCabinets ? (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId='droppable-list'>
                      {provided => (
                        <List {...provided.droppableProps} ref={provided.innerRef}>
                          {includedCabinets.map((cabinet, index) => (
                            <Draggable key={cabinet.id} draggableId={cabinet.id} index={index}>
                              {provided => (
                                <ListItem
                                  key={cabinet.id}
                                  disablePadding
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    userSelect: 'none',
                                    overflow: 'visible' // Ensure ListItemButton is not scrollable
                                  }}
                                >
                                  <ListItemButton
                                    sx={{
                                      borderRadius: '20px',
                                      boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.9)',
                                      marginBottom: '15px',
                                      marginRight: '15px',
                                      overflow: 'visible' // Ensure ListItemButton is not scrollable
                                    }}
                                  >
                                    <ListItemIcon
                                      id={`${cabinet.id}CabinetItemRemoveButton`}
                                      onClick={() => {
                                        setIncludeCabinets(cabinets => includedCabinets.filter(c => c.id !== cabinet.id))
                                      }}
                                    >
                                      <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={cabinet.name} />
                                  </ListItemButton>
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <CenteredDiv>Selected a layout to edit or create a new one.</CenteredDiv>
                )}
              </CardContent>
              <CardActions>
                <PrimaryButton
                  id='saveLayoutButton'
                  onClick={async () => {
                    await DtsReportsApi.setCardLayouts({
                      ...selectedLayout,
                      id: selectedLayout.id === '' ? newLayoutName : selectedLayout.id,
                      cabinets: includedCabinets
                    }).then(async () => await layoutsRefetch())
                    enqueueSnackbar('Saved layout configuration.', SnackbarVariants.SUCCESS)
                  }}
                >
                  Save
                </PrimaryButton>
              </CardActions>
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}
