import React, { useState, useEffect, useMemo } from 'react'
import { CenteredDiv, CustomSelect, CloseIconButton, WtxColors } from '@wavetronix/common-components'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import DtsReportsApi from '../api/DtsReportsApi'
import dayjs from 'dayjs'
import StatusCardEx from './StatusCardEx'
import { Switch, Toolbar, IconButton, Dialog, DialogContent, DialogTitle, Grid, Slide, Box, Tooltip } from '@mui/material'
import version from '../env/version.json'
import LoadingPageMessage from './LoadingPageMessage'
import ConfigureLayouts from './ConfigureLayouts'
import { useCookies } from 'react-cookie'
import EditIcon from '@mui/icons-material/Edit'
import { useMediaQuery } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import HelpIcon from '@mui/icons-material/Help'

const DIRECTION = {
  BACK: -1,
  FORWARD: 1
}

const useBreakpointValue = () => {
  // Use media queries directly at the top level
  const isXLg = useMediaQuery('(min-width: 1536px)')
  const isLg = useMediaQuery('(min-width: 1200px) and (max-width: 1535px)')
  const isMd = useMediaQuery('(min-width: 900px) and (max-width: 1199px)')
  const isSm = useMediaQuery('(min-width: 600px) and (max-width: 899px)')
  const isXs = useMediaQuery('(min-width: 370px) and (max-width: 599px)')
  const isExtraSmall = useMediaQuery('(max-width: 369px)')

  // Build an object containing all breakpoints
  const breakpoints = useMemo(
    () => ({
      isXLg,
      isLg,
      isMd,
      isSm,
      isXs,
      isExtraSmall
    }),
    [isXLg, isLg, isMd, isSm, isXs, isExtraSmall]
  )

  // Compute the value based on the active breakpoint
  const breakpointValue = useMemo(() => {
    if (breakpoints.isXLg) return 16
    if (breakpoints.isLg) return 12
    if (breakpoints.isMd) return 8
    if (breakpoints.isSm) return 4
    if (breakpoints.isXs) return 2
    if (breakpoints.isExtraSmall) return 1
    return 2 // Default value if no match
  }, [breakpoints])

  return { breakpoints, breakpointValue }
}

const checkForChangesIntervalInSeconds = 15 * 60
const switchViewIntervalInSeconds = 15

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const classes = {
  content: {
    backgroundColor: 'transparent',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: 20
  }
}

export default function Overview({ isLoginRequired, reportsData, reportsDataLoading, reportsRefetch }) {
  const { instance, accounts } = useMsal()
  const { breakpointValue } = useBreakpointValue()
  const [cookies, setCookie] = useCookies(['layoutName'])
  const [refreshDate, setRefreshDate] = useState('')
  const [refreshTime, setRefreshTime] = useState('')
  const [enableEditGrid, setEditGrid] = useState(false)
  const [showLayoutOptions, setShowLayoutOptions] = useState(false)
  const [selectedLayout, setSelectedLayout] = useState('')
  const [editLayoutOptions, setEditLayoutOptions] = useState(false)
  const [currentCarousel, setCurrentCarousel] = useState(1)

  const {
    data: allLayouts,
    isLoading: allLayoutsLoading,
    refetch: layoutsRefetch
  } = useQuery({
    queryKey: ['allLayouts'],
    queryFn: async () => {
      return await DtsReportsApi.getCardLayouts()
    }
  })

  const { data: dbLayouts } = useQuery({
    queryKey: ['overviewLayout', selectedLayout],
    queryFn: async () => {
      let res = await DtsReportsApi.getCardLayout(selectedLayout)
      return res
    },
    enabled: !!selectedLayout && selectedLayout !== ''
  })

  const { data: changeId } = useQuery({
    queryKey: ['changeIdData'],
    queryFn: async () => await DtsReportsApi.hasChanges(isLoginRequired, instance, accounts),
    refetchInterval: checkForChangesIntervalInSeconds * 1000
  })

  const layoutOptions = useMemo(() => {
    if (allLayouts) {
      return allLayouts.map(layout => layout.id)
    }
  }, [allLayouts])

  const cabinets = useMemo(() => {
    if (reportsData && reportsData.cabinetOverviews && dbLayouts) {
      const overviewMap = reportsData.cabinetOverviews.reduce((map, obj) => {
        map[obj.id] = obj
        return map
      }, {})

      return dbLayouts.cabinets.map(cabinet => overviewMap[cabinet.id])
    }
  }, [reportsData, dbLayouts])

  const cabinetCarousel = useMemo(() => {
    const chunkArrayToObject = (arr, bucketSize) => {
      const result = {}
      let key = 1

      for (let i = 0; i < arr.length; i += bucketSize) {
        result[key] = arr.slice(i, i + bucketSize)
        key++
      }

      return result
    }
    if (cabinets && breakpointValue) {
      //split cabinets into buckets with max number = breakpoint values in them
      let carouselMap = chunkArrayToObject(cabinets, breakpointValue)

      return { carousel: carouselMap, numberOfPages: Object.keys(carouselMap).length }
    }
  }, [breakpointValue, cabinets])

  useEffect(() => {
    if (cabinetCarousel && cabinetCarousel.numberOfPages) {
      const interval = setInterval(() => {
        setCurrentCarousel(s => {
          let newSection = s + 1
          if (newSection > cabinetCarousel.numberOfPages) return 1
          else return newSection
        })
      }, switchViewIntervalInSeconds * 1000)
      return () => clearInterval(interval)
    }
  }, [cabinetCarousel])

  useEffect(() => {
    if (!cookies) {
      setCookie('layoutName', 'InternalLayout', { path: '/' })
      setSelectedLayout('InternalLayout')
    } else if (cookies && (!cookies['layoutName'] || cookies['layoutName'] === '')) {
      setSelectedLayout('InternalLayout')
    } else {
      setSelectedLayout(cookies['layoutName'])
    }
  }, [cookies, selectedLayout, setCookie])

  useEffect(() => {
    reportsRefetch()
  }, [changeId, reportsRefetch])

  useEffect(() => {
    if (reportsData) {
      let currentTime = new Date()
      setRefreshDate(dayjs(currentTime).format('YYYY-MM-DD'))
      setRefreshTime(dayjs(currentTime).format('HH:mm:ss'))
    }
  }, [reportsData, changeId, setRefreshDate, setRefreshTime])

  document.getElementById('appTitle').innerHTML = `Overview`

  if (reportsDataLoading) {
    return <LoadingPageMessage message={'Loading product report overview data... Please wait.'} />
  }

  const moveCarousel = direction => {
    setCurrentCarousel(state => state + direction)
  }

  const onCheckChangeEditMode = async e => {
    let checked = e.target.checked

    if (checked === true) {
      setEditGrid(true)
      setShowLayoutOptions(true)
    } else {
      setEditGrid(false)
      setShowLayoutOptions(false)
    }
  }

  return (
    <>
      <Dialog
        id='configureLayoutsDialog'
        fullScreen
        open={editLayoutOptions}
        onClose={() => setEditLayoutOptions(false)}
        sx={{ background: '#e0e1dd' }}
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            backgroundColor: 'black',
            color: 'white',
            height: '64px',
            boxSizing: 'border-box'
          }}
        >
          <Grid container>
            <Grid item md={11} sm={11} xs={11}></Grid>
            <Grid item md={1} sm={1} xs={1}>
              <CloseIconButton
                id='configureLayoutsCloseButton'
                onClick={() => setEditLayoutOptions(false)}
                style={{ float: 'right' }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ background: '#e0e1dd' }}>
          <ConfigureLayouts
            allLayouts={allLayouts}
            allLayoutsLoading={allLayoutsLoading}
            reportsData={reportsData}
            layoutsRefetch={layoutsRefetch}
          />
        </DialogContent>
      </Dialog>
      <div style={classes.content}>
        <Toolbar />
        <div>
          <CenteredDiv>
            <span style={{ fontFamily: 'Proxima Nova', fontWeight: 'bold', fontSize: '18px' }}>Last Updated on </span>
            <span style={{ fontFamily: 'Source Code Pro', fontSize: '18px', marginLeft: '15px' }}>{refreshDate}</span>
            <span style={{ fontFamily: 'Source Code Pro', fontSize: '18px', marginLeft: '15px' }}>{refreshTime}</span>
          </CenteredDiv>
          <span
            style={{
              float: 'right',
              transform: 'translate(100%, -100%)'
            }}
          >{`v ${version.version}`}</span>
        </div>
        <Box sx={{ width: '100%', display: 'flex' }}>
          <IconButton disabled={currentCarousel <= 1} onClick={() => moveCarousel(DIRECTION.BACK)}>
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ flex: 'auto 1 1' }} />
          <IconButton
            disabled={cabinetCarousel && cabinetCarousel.numberOfPages ? currentCarousel >= cabinetCarousel.numberOfPages : true}
            onClick={() => moveCarousel(DIRECTION.FORWARD)}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
        <Grid container spacing={1}>
          {currentCarousel && cabinetCarousel
            ? cabinetCarousel.carousel[currentCarousel].map(item => {
                return (
                  <Grid item key={item.id} xxl={2} xl={3} lg={4} md={6} sm={12} xs={12}>
                    <StatusCardEx statusItem={item} key={item.id} disableNavigation={enableEditGrid} />
                  </Grid>
                )
              })
            : []}
        </Grid>
        <div style={{ float: 'right', marginRight: '20px', marginBottom: '15px', display: 'flex' }}>
          {showLayoutOptions === true ? (
            <div style={{ display: 'flex' }}>
              <Tooltip title='Grid placement is left to right, top to bottom based on the order of the cabinets in the selected layout.'>
                <HelpIcon sx={{ color: WtxColors.ASPHALT, marginTop: '7px' }} />
              </Tooltip>
              <div style={{ display: 'flex' }}>
                <IconButton id='cardLayoutEditButton' onClick={() => setEditLayoutOptions(true)}>
                  <EditIcon />
                </IconButton>
                <CustomSelect
                  id='layoutSelect'
                  style={{ marginRight: '15px', minWidth: '200px' }}
                  label='Layout'
                  value={selectedLayout}
                  options={layoutOptions}
                  onChange={e => {
                    setSelectedLayout(f => e.target.value)
                    setCookie('layoutName', e.target.value, { path: '/' })
                  }}
                />
              </div>
            </div>
          ) : (
            <span />
          )}
          <Switch id='editLayoutSwitch' color='primary' onChange={e => onCheckChangeEditMode(e)} />
          EDIT MODE
        </div>
      </div>
    </>
  )
}
