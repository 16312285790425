import {
  CustomSelect,
  CustomDataGrid,
  WtxColors,
  SnackbarVariants,
  inDateRange,
  CustomCheckbox
} from '@wavetronix/common-components'
import {
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Grid,
  Chip,
  IconButton,
  Stack,
  FormGroup
} from '@mui/material'
import { useState, useMemo, useEffect } from 'react'
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff'
import BarChartIcon from '@mui/icons-material/BarChart'
import MenuIcon from '@mui/icons-material/Menu'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { useSnackbar } from 'notistack'
import minMax from 'dayjs/plugin/minMax'
import DtsReportsApi from '../api/DtsReportsApi'
import NumbersIcon from '@mui/icons-material/Numbers'
import PercentIcon from '@mui/icons-material/Percent'
import BallotIcon from '@mui/icons-material/Ballot'
import ProblemChildrenGraph from './graphs/ProblemChildrenGraph'
import PassFailBarGraph from './graphs/PassFailBarGraph'
import PassedPercentLineGraph from './graphs/PassedPercentLineGraph'
import CirclePassFailGraph from './graphs/CirclePassFailGraph'
import CircularProgressWithLabel from './CircularProgressWithLabel'
import { env } from '../index.js'
import { v4 as uuidv4 } from 'uuid'
import { versionComparator } from '../utils/sortComparators'
import { CONNECTION_TYPES, TEST_TYPES, DC_TYPES, VOLT_TYPES } from '../utils/connectionTypes.js'

dayjs.extend(minMax)

const filterTests = (filter, tests) =>
  tests.filter(test => {
    return (
      (filter.branch === 'All' || filter.branch === test.branch) &&
      (filter.connectionType === 'All' ||
        (filter.connectionType === 'LAN' && test.connectionType === 'LAN') ||
        (filter.connectionType === 'WAN' && test.connectionType === 'WAN') ||
        (filter.connectionType === 'DUAL' && test.connectionType === 'DUAL')) &&
      (filter.version === '' || (test.softwareVersion && test.softwareVersion.includes(filter.version))) &&
      inDateRange(test.startTime, filter.startDate, filter.endDate) &&
      (filter.cabinetName === 'All' || test.cabinetName.toLowerCase() === filter.cabinetName.toLowerCase()) &&
      (filter.networkType === 'All' ? true : filter.networkType === 'Customer' ? test.isHappyPath : !test.isHappyPath) &&
      (filter.dcType === 'All' ? true : filter.dcType === 'DC' ? test.isDC : !test.isDC) &&
      (filter.voltType === 'All' ? true : filter.voltType === '12V' ? test.isLow : !test.isLow)
    )
  })

export const DEFAULT_TEST_FILTER = {
  branch: 'Master',
  startDate: null,
  endDate: null,
  version: '',
  cabinetName: 'All',
  originalStartDate: null,
  testType: 'All Tests',
  connectionType: 'All',
  networkType: 'All',
  dcType: 'All',
  voltType: 'All'
}

const columns = [
  {
    field: 'passed',
    headerName: 'Passed',
    flex: 0.5,
    renderCell: data => {
      let passedCount = data.row.results.filter(r => r.passed === true).length
      let totalCount = data.row.results.length
      let passPercent = (passedCount / totalCount) * 100
      return <CircularProgressWithLabel value={passPercent} sx={{ color: WtxColors.GROWTH_GREEN }} />
    }
  },
  {
    field: 'branch',
    headerName: 'Branch',
    flex: 1
  },
  {
    field: 'connectionType',
    headerName: 'Connection',
    flex: 1
  },
  {
    field: 'isHappyPath',
    headerName: 'Network',
    flex: 1,
    valueGetter: u => (u.row.isHappyPath === true ? 'Customer' : 'Wavetronix')
  },
  {
    field: 'isDC',
    headerName: 'DC Voltage',
    flex: 1,
    valueGetter: u => (u.row.isDC ? (u.row.isLow ? '12V' : '24V') : '-')
  },
  {
    field: 'softwareVersion',
    headerName: 'Version',
    flex: 1
  },
  {
    field: 'cabinetName',
    headerName: 'Cabinet Name',
    flex: 1
  },
  {
    field: 'machineName',
    headerName: 'Machine Name',
    flex: 1
  },
  {
    field: 'startTime',
    headerName: 'Time',
    flex: 1,
    valueGetter: u => dayjs(u.row.startTime).format('MM/DD/YYYY hh:mm:ss')
  }
]

const BAR_TYPE_OPTIONS = ['cabinetName', 'softwareVersion', 'machineName', 'time']
const TIME_OPTIONS = { hour: 13, day: 10, month: 7 }

export default function ProductMetricsDisplay({ selectedProduct, filter, setFilter, testMap }) {
  const { enqueueSnackbar } = useSnackbar()
  const [displayValue, setDisplayValue] = useState('circle')
  const [barOption, setBarOption] = useState('softwareVersion')
  const [timeOption, setTimeOption] = useState('day')
  const [sortOption, setSortOption] = useState('percent')
  const [testCount, setTestCount] = useState(0)
  const [showPercentLabels, setShowPercentLabels] = useState(false)
  const cabinetOptions = useMemo(() => {
    if (selectedProduct) {
      let res = new Set(selectedProduct.results.map(t => t.cabinetName))
      return Array.from(['All', ...res])
    }
  }, [selectedProduct])

  const filteredTests = useMemo(() => {
    if (selectedProduct) {
      return filterTests(filter, selectedProduct.results).sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
    }
  }, [selectedProduct, filter])

  const origDates = useMemo(() => {
    let datesObj = {
      startDate: null,
      endDate: null
    }
    if (selectedProduct && selectedProduct.results) {
      let dates = selectedProduct.results.map(test => dayjs(test.startTime))

      datesObj = { startDate: dayjs.min(dates), endDate: dayjs.max(dates) }
    }

    return datesObj
  }, [selectedProduct])

  useEffect(() => {
    if (selectedProduct && origDates) {
      setFilter(f => ({
        ...f,
        startDate: f.startDate ? f.startDate : origDates.startDate,
        endDate: f.endData ? f.endDate : origDates.endDate,
        originalStartDate: origDates.startDate
      }))
    }
  }, [selectedProduct, setFilter, origDates])

  const circleData = useMemo(() => {
    if (filteredTests) {
      let circleRes = {
        passed: 0,
        failed: 0,
        total: 0
      }

      for (let test of filteredTests) {
        for (let result of test.results) {
          circleRes.total += 1
          if (result.passed === true) {
            circleRes.passed += 1
          } else {
            circleRes.failed += 1
          }
        }
      }

      setTestCount(circleRes.total)

      return [
        {
          name: 'Passed',
          value: circleRes.passed
        },
        { name: 'Failed', value: circleRes.failed }
      ]
    }
  }, [filteredTests])

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) {
      setDisplayValue(newAlignment)
    }
  }

  const barData = useMemo(() => {
    let oneTimeMessage = false
    const calculateTime = (time, type) => {
      return time.slice(0, TIME_OPTIONS[type])
    }

    if (filteredTests) {
      let mapResult = {}
      if (barOption === 'time') {
        mapResult = filteredTests.reduce((map, obj) => {
          let objTime = calculateTime(obj.startTime, timeOption)
          if (!(objTime in map)) {
            map[`${objTime}`] = {
              option: objTime,
              passed: 0,
              failed: 0,
              problemChildren: [],
              problemChildrenCount: 0
            }
          }

          let filteredResults = obj.results.filter(test => testMap[`${test.testName}`] === true)

          for (let singleTest of filteredResults) {
            if (!(`${singleTest.testName} Passed` in map[`${objTime}`])) {
              map[`${objTime}`][`${singleTest.testName} Passed`] = 0
            }
            if (!(`${singleTest.testName} Failed` in map[`${objTime}`])) {
              map[`${objTime}`][`${singleTest.testName} Failed`] = 0
            }

            if (singleTest.passed === true) {
              map[`${objTime}`].passed += 1
              map[`${objTime}`][`${singleTest.testName} Passed`] += 1
            } else {
              map[`${objTime}`].failed += 1
              map[`${objTime}`][`${singleTest.testName} Failed`] += 1
            }
          }

          let passed = map[`${objTime}`].passed
          let total = map[`${objTime}`].passed + map[`${objTime}`].failed

          map[`${objTime}`].passedPercent = ((passed / total) * 100).toFixed(2)

          for (let singleTest of filteredResults) {
            let passed = map[`${objTime}`][`${singleTest.testName} Passed`]
            let total = map[`${objTime}`][`${singleTest.testName} Passed`] + map[`${objTime}`][`${singleTest.testName} Failed`]
            let percentage = ((passed / total) * 100).toFixed(2)

            map[`${objTime}`][`${singleTest.testName} Percent Passed`] = percentage
            if (!map[`${objTime}`].problemChildren.includes(singleTest.testName)) {
              map[`${objTime}`].problemChildren.push(singleTest.testName)
              map[`${objTime}`].problemChildrenCount += 1
            }
          }

          return map
        }, {})
      } else {
        mapResult = filteredTests.reduce((map, obj) => {
          if (!(barOption in obj)) {
            if (oneTimeMessage === false) {
              enqueueSnackbar(
                `A test report does not contain the variable ${barOption}. Graph will be incomplete or empty.`,
                SnackbarVariants.ERROR
              )
              oneTimeMessage = true
            }
            return map
          }

          if (!(`${obj[`${barOption}`]}` in map)) {
            map[`${obj[`${barOption}`]}`] = {
              option: `${obj[`${barOption}`]}`,
              passed: 0,
              failed: 0,
              problemChildren: [],
              problemChildrenCount: 0
            }
          }

          let filteredResults = obj.results.filter(test => testMap[`${test.testName}`] === true)

          for (let singleTest of filteredResults) {
            if (!(`${singleTest.testName} Passed` in map[`${obj[`${barOption}`]}`])) {
              map[`${obj[`${barOption}`]}`][`${singleTest.testName} Passed`] = 0
            }
            if (!(`${singleTest.testName} Failed` in map[`${obj[`${barOption}`]}`])) {
              map[`${obj[`${barOption}`]}`][`${singleTest.testName} Failed`] = 0
            }

            if (singleTest.passed === true) {
              map[`${obj[`${barOption}`]}`].passed += 1
              map[`${obj[`${barOption}`]}`][`${singleTest.testName} Passed`] += 1
            } else {
              map[`${obj[`${barOption}`]}`].failed += 1
              map[`${obj[`${barOption}`]}`][`${singleTest.testName} Failed`] += 1
            }
          }

          let passed = map[`${obj[`${barOption}`]}`].passed
          let total = map[`${obj[`${barOption}`]}`].passed + map[`${obj[`${barOption}`]}`].failed

          map[`${obj[`${barOption}`]}`].passedPercent = ((passed / total) * 100).toFixed(2)

          for (let singleTest of filteredResults) {
            let passed = map[`${obj[`${barOption}`]}`][`${singleTest.testName} Passed`]
            let total =
              map[`${obj[`${barOption}`]}`][`${singleTest.testName} Passed`] +
              map[`${obj[`${barOption}`]}`][`${singleTest.testName} Failed`]
            let percentage = (passed / total) * 100

            map[`${obj[`${barOption}`]}`][`${singleTest.testName} Percent Passed`] = percentage.toFixed(2)
            if (!map[`${obj[`${barOption}`]}`].problemChildren.includes(singleTest.testName)) {
              map[`${obj[`${barOption}`]}`].problemChildren.push(singleTest.testName)
              map[`${obj[`${barOption}`]}`].problemChildrenCount += 1
            }
          }

          return map
        }, {})
      }

      return Object.values(mapResult).sort((a, b) =>
        barOption === 'softwareVersion' ? versionComparator(a.option, b.option) : a.option > b.option ? 1 : -1
      )
    }
  }, [barOption, filteredTests, enqueueSnackbar, timeOption, testMap])

  return (
    <div>
      <Typography variant='h4'>{selectedProduct.testName}</Typography>
      <Grid container sx={{ marginTop: '15px', width: '100%' }}>
        <Grid item lg={8} md={12}>
          <Box sx={{ display: 'flex' }}>
            <CustomSelect
              id='branchSelect'
              label='Branch'
              style={{ width: '150px' }}
              value={filter.branch}
              options={['Dev', 'Integration', 'Master']}
              onChange={e => setFilter(f => ({ ...f, branch: e.target.value }))}
            />
            <CustomSelect
              id='connectionTypeSelect'
              label='Connection'
              style={{ width: '150px' }}
              value={filter.connectionType}
              options={CONNECTION_TYPES}
              onChange={e => setFilter(f => ({ ...f, connectionType: e.target.value }))}
            />
            <FormGroup sx={{ marginLeft: '10px' }} row>
              <CustomSelect
                id='isHappySelect'
                label='Test Type'
                style={{ width: '150px' }}
                value={filter.networkType}
                options={TEST_TYPES}
                onChange={e => setFilter(f => ({ ...f, networkType: e.target.value }))}
              />
              <CustomSelect
                id='isDCSelect'
                label='DC Type'
                style={{ width: '150px' }}
                value={filter.dcType}
                options={DC_TYPES}
                onChange={e => setFilter(f => ({ ...f, dcType: e.target.value }))}
              />
              <CustomSelect
                id='isLowSelect'
                label='Voltage Type'
                style={{ width: '150px' }}
                value={filter.voltType}
                options={VOLT_TYPES}
                onChange={e => setFilter(f => ({ ...f, voltType: e.target.value }))}
              />
            </FormGroup>
          </Box>
          <Box sx={{ display: 'flex', marginTop: '10px' }}>
            <TextField
              id='versionTextField'
              label='Version'
              size='small'
              value={filter.version}
              onChange={e => setFilter(f => ({ ...f, version: e.target.value }))}
            />
            <CustomSelect
              id='cabinetSelect'
              label='Cabinet Name'
              style={{ width: '250px' }}
              value={filter.cabinetName}
              options={cabinetOptions ? cabinetOptions : []}
              onChange={e => setFilter(f => ({ ...f, cabinetName: e.target.value }))}
            />
          </Box>
        </Grid>
        <Grid item lg={4} md={12}>
          <Stack sx={{ float: 'right' }}>
            <Box sx={{ float: 'right', display: 'flex', marginRight: '0px', marginLeft: 'auto' }}>
              <Chip
                id='pastWeekChip'
                label='Past Week'
                onClick={() => {
                  var newStartDate = dayjs(filter.endDate).add(-7, 'day')

                  setFilter(f => ({ ...f, startDate: newStartDate }))
                }}
              />
              <Chip
                id='pastMonthChip'
                label='Past Month'
                onClick={() => {
                  var newStartDate = dayjs(filter.endDate).add(-1, 'month')

                  setFilter(f => ({ ...f, startDate: newStartDate }))
                }}
              />
              <Chip
                id='resetDateChip'
                label='Reset'
                onClick={() => {
                  setFilter({
                    ...DEFAULT_TEST_FILTER,
                    originalStartDate: origDates.startDate,
                    startDate: origDates.startDate,
                    endDate: origDates.endDate
                  })
                }}
              />
            </Box>
            <Box sx={{ float: 'right', marginTop: '15px', display: 'flex' }}>
              <DatePicker
                id='startDatePicker'
                label='From'
                inputFormat='MM/DD/YYYY'
                value={filter.startDate}
                onChange={date => setFilter(f => ({ ...f, startDate: date }))}
                textField={params => <TextField size='small' {...params} />}
              />
              <DatePicker
                id='endDatePicker'
                label='To'
                inputFormat='MM/DD/YYYY'
                value={filter.endDate}
                onChange={date => setFilter(f => ({ ...f, endDate: date }))}
                textField={params => <TextField size='small' sx={{ marginLeft: '5px' }} {...params} />}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '15px' }}>
        <ToggleButtonGroup value={displayValue} exclusive onChange={handleAlignment}>
          <ToggleButton id='circleToggleButton' value='circle'>
            <DataSaverOffIcon />
          </ToggleButton>
          <ToggleButton id='grapheToggleButton' value='graph'>
            <BarChartIcon />
          </ToggleButton>
          <ToggleButton id='listToggleButton' value='list'>
            <MenuIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          marginTop: '15px',
          bgcolor: 'background.default',
          borderRadius: '30px'
        }}
      >
        {displayValue === 'list' ? (
          <div style={{ margin: '0px 15px', paddingTop: '15px' }}>
            <CustomDataGrid
              getRowId={row => {
                return uuidv4()
              }}
              rows={filteredTests}
              columns={columns}
              cursor='pointer'
              onRowClick={async row => {
                const exists = await DtsReportsApi.logFileExists(row.row.id)

                if (exists === true) {
                  window.open(`${env.urls.dtsURL}/logs/${row.row.id}`, '_blank')
                } else {
                  enqueueSnackbar(`Log for this test does not exist`, {
                    variant: 'warning',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    }
                  })
                }
              }}
            />
          </div>
        ) : displayValue === 'graph' ? (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <CustomSelect
                id='graphBarOptionSelect'
                label='Graph Option'
                style={{ width: '200px', marginTop: '15px', marginLeft: '15px' }}
                value={barOption}
                options={BAR_TYPE_OPTIONS}
                onChange={e => setBarOption(e.target.value)}
              />
              {barOption === 'time' ? (
                <CustomSelect
                  id='graphTimeOptionSelect'
                  label='Time Option'
                  style={{ width: '200px', marginTop: '15px' }}
                  value={timeOption}
                  options={Object.keys(TIME_OPTIONS)}
                  onChange={e => setTimeOption(e.target.value)}
                />
              ) : (
                <></>
              )}
              <Box sx={{ flex: '1 1 auto' }} />
              <Stack>
                <Box>
                  <IconButton id='sortCountButton' onClick={() => setSortOption('count')} sx={{ margin: '9px 0px' }}>
                    <NumbersIcon sx={{ color: sortOption === 'count' ? WtxColors.IQ_BLUE : 'default' }} fontSize='small' />
                  </IconButton>
                  <IconButton id='percentCountButton' onClick={() => setSortOption('percent')} sx={{ margin: '9px 0px' }}>
                    <PercentIcon sx={{ color: sortOption === 'percent' ? WtxColors.IQ_BLUE : 'default' }} fontSize='small' />
                  </IconButton>
                  <IconButton
                    id='ballotCountButton'
                    onClick={() => setSortOption('ballot')}
                    sx={{ margin: '9px 0px', marginRight: '15px' }}
                  >
                    <BallotIcon sx={{ color: sortOption === 'ballot' ? WtxColors.IQ_BLUE : 'default' }} fontSize='small' />
                  </IconButton>
                </Box>
              </Stack>
            </Box>

            <div style={{ padding: '15px' }}>
              {sortOption === 'ballot' ? (
                <>
                  <ProblemChildrenGraph
                    showPercentLabels={showPercentLabels}
                    barData={barData}
                    testTypes={testMap ? Object.keys(testMap) : []}
                  />
                  <Box>
                    <CustomCheckbox
                      id='showPercentPointsCheckbox'
                      checked={showPercentLabels === true}
                      onChange={e => setShowPercentLabels(value => value === false)}
                    />
                    Show Percent Points
                  </Box>
                </>
              ) : sortOption === 'count' ? (
                <PassFailBarGraph barData={barData} />
              ) : (
                <>
                  <PassedPercentLineGraph barData={barData} showPercentLabels={showPercentLabels} />
                  <Box>
                    <CustomCheckbox
                      id='showPercentPointsCheckbox'
                      checked={showPercentLabels === true}
                      onChange={e => setShowPercentLabels(value => value === false)}
                    />
                    Show Percent Points
                  </Box>
                </>
              )}
            </div>
          </Box>
        ) : displayValue === 'circle' ? (
          <CirclePassFailGraph data={circleData} testCount={testCount} />
        ) : (
          <div>Error: Invalid display value option.</div>
        )}
      </Box>
    </div>
  )
}
